import React, { useEffect, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useNavigate } from "react-router-dom";
import Home from "../Home/Home";

const api_url = process.env.REACT_APP_API_URL;

const WebComplaint = () => {
  const navigate = useNavigate();

  const [complaintData, setcomplaintData] = useState([]);
  const [profileData, setProfileData] = useState([]);

  const [view, setView] = useState(false);

  useEffect(() => {
    fetch(`${api_url}/getuser`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userObject = data.find(
          (user) => user._id === sessionStorage.userId
        );
        // setIsValidUser(userObject.active);
        if (!userObject.active) {
          navigate("/");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  });

  useEffect(() => {
    const profilesDataFetcher = async () => {
      try {
        const response3 = await fetch(`${api_url}/getprofiles`);
        if (!response3.ok) {
          throw new Error(`HTTP error! Status:${response3.status}`);
        }
        const resData3 = await response3.json();
        const UserProfileData = resData3.filter(
          (profile) => profile.profileName === sessionStorage.userProfile
        );
        setProfileData(UserProfileData);
        setView(UserProfileData[0].status.webCareer.view);
        // setCreate(UserProfileData[0].status.review.create);
      } catch (error) {
        console.error("Error fetching profiles data:", error);
      }
    };

    profilesDataFetcher();
  }, []);

  useEffect(() => {
    const fetchWebLeads = async () => {
      try {
        const response = await fetch(`${api_url}/webComplaint`);
        const result = await response.json();

        if (response.ok) {
          setcomplaintData(result.data);
        } else {
          console.error("Error fetching data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching web leads:", error);
      }
    };

    fetchWebLeads();
  }, [profileData]);

  const getDateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      size: 10,
    },

    {
      accessorKey: "department",
      header: "Department",
      size: 10,
    },
    {
      accessorKey: "language",
      header: "Language",
      size: 10,
    },
    {
      accessorKey: "email",
      header: "Email  ",
      size: 10,
    },
    {
      accessorKey: "number",
      header: "Phone",
      size: 10,
    },
    { accessorKey: "tag", header: "tag", size: 10 },
    { accessorKey: "messages", header: "messages", size: 100 },
    {
      accessorKey: "createdAt",
      header: "Created At",
      size: 10,
      accessorFn: (row) => getDateFormat(row.createdAt),
    },
    {
      accessorKey: "updatedAt",
      header: "Updated At",
      size: 10,
      accessorFn: (row) => getDateFormat(row.updatedAt),
    },
  ];

  const table = useMaterialReactTable({
    data: complaintData,
    columns,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableHiding: false,
    paginationDisplayMode: "pages",
    muiTablePaperProps: {
      elevation: 0,
    },
    globalFilterFn: "contains",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  });

  return (
    <div>
      <Home />
      {view && (
        <div>
          <div className="table-button-container">
            <div className="niveshartha-btn-container">
              {/* <Link to="/addwebleads">
              <button className="niveshartha_btn_add">Add Webleads</button>
            </Link> */}
            </div>
            <div className="user-table-container">
              <MaterialReactTable table={table} />
            </div>
          </div>
        </div>
      )}
      {!view && (
        <div className="user-table-container loading-gif">
          <img src="/loading.gif" alt="loading"></img>
        </div>
      )}
    </div>
  );
};

export default WebComplaint;
