import React, { useState, useEffect } from "react";
import "./sendCall.css";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormText,
} from "reactstrap";
import Home from "../Home/Home";
import ConfirmationPopup from "./ConfirmationPopup";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const api_url = process.env.REACT_APP_API_URL;
const SendCall = () => {
  const navigate = useNavigate();

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [dealType, setDealtype] = useState("");
  const [quantity, setQuantity] = useState("");
  const [script, setScript] = useState("");
  const [position, setPosition] = useState("");
  const [price1, setPrice1] = useState("");
  const [target, setTarget] = useState("");
  const [stopLoss, setStopLoss] = useState("");
  const [uploadFile, setFileUpload] = useState("");
  const [actualProductList, setActualProductList] = useState([]);
  const [fileSizeError, setFileSizeError] = useState("");

  const [view, setView] = useState(false);
  const [create, setCreate] = useState(false);
  const togglePopup = () => setPopupOpen(!isPopupOpen);

  // ____________________________________________________________________________________________

  const clearformdata = () => {
    document.getElementById("productName").value = "";
    document.getElementById("quantity").value = "";
    document.getElementById("dealType").value = "";
    document.getElementById("script").value = "";
    document.getElementById("position").value = "";
    document.getElementById("price1").value = "";
    document.getElementById("target").value = "";
    document.getElementById("stopLoss").value = "";
    document.getElementById("description").value = "";
  };

  const profilesDataFetcher = async () => {
    try {
      const response3 = await fetch(`${api_url}/getprofiles`);
      if (!response3.ok) {
        throw new Error(`HTTP error! Status:${response3.status}`);
      }
      const resData3 = await response3.json();
      const UserProfileData = resData3.filter(
        (profile) => profile.profileName === sessionStorage.userProfile
      );
      setView(UserProfileData[0].status.sendCall.view);
      setCreate(UserProfileData[0].status.sendCall.create);
    } catch (error) {
      console.error("Error fetching profiles data:", error);
    }
  };

  profilesDataFetcher();
  // _________________________________________________________________________

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(document.querySelector("#productForm"));

    const maxFileSize = 20 * 1024;
    const file = data.get("productImage");

    if (file && file.size > maxFileSize) {
      setFileSizeError(
        "File size exceeds the allowed limit (20KB). Please select a smaller file."
      );

      return;
    }
    setDealtype(e.currentTarget.dealType.value);
    setScript(e.currentTarget.script.value);
    setPosition(e.currentTarget.position.value);
    setPrice1(e.currentTarget.price1.value);
    setTarget(e.currentTarget.target.value);
    setStopLoss(e.currentTarget.stopLoss.value);
    togglePopup();
  };

  const [products, setProducts] = useState([]);
  // const handleProductSelection = (options) => {
  //   const selected = Array.from(options)
  //     .filter((option) => option.selected)
  //     .map((option) => option.value);

  //     setProducts(selected);
  // };
  const [adminName, setAdminName] = useState(() =>
  sessionStorage.getItem("displayName")
  );

  
  const handleChangePrice = (e) => {
    const inputValue = e.target.value;
    // Regular expression to allow only numbers (integer or decimal)
    const regex = /^\d*\.?\d*$/; // Allows integers and decimals
    if (inputValue === "" || regex.test(inputValue)) {
      setPrice1(inputValue);
    }
  };
  const handleChangeTarget = (e) => {
    const inputValue = e.target.value;
    // Regular expression to allow only numbers (integer or decimal)
    const regex = /^\d*\.?\d*$/; // Allows integers and decimals
    if (inputValue === "" || regex.test(inputValue)) {
      setTarget(inputValue);
    }
  };
  const handleChangeStopLoss = (e) => {
    const inputValue = e.target.value;
    // Regular expression to allow only numbers (integer or decimal)
    const regex = /^\d*\.?\d*$/; // Allows integers and decimals
    if (inputValue === "" || regex.test(inputValue)) {
      setStopLoss(inputValue);
    }
  };
  const handleChangequatity = (e) => {
    const inputValue = e.target.value;
    // Regular expression to allow only numbers (integer or decimal)
    const regex = /^\d*\.?\d*$/; // Allows integers and decimals
    if (inputValue === "" || regex.test(inputValue)) {
      setQuantity(inputValue);
    }
  };

  const handlePopupConfirm = async () => {

    try {
      let url = `${api_url}/sendcall`;
      const data = new FormData(document.querySelector("#productForm"));
      // console.log("1")
      const payload = {
        productName: data.getAll("productName"),
        quantity: data.get("quantity"),
        dealType: data.get("dealType"),
        script: data.get("script"),
        position: data.get("position"),
        price1: +price1,
        price2: data.get("price2"),
        price3: data.get("price3"),
        target: +target,
        stopLoss: +stopLoss,
        description: data.get("description"),
        pnl: 0,
        statusValue: data.get("statusValue"),
        created_by: adminName,
        updated_by: "",
      };

      // console.log("✌️payload --->", payload);

      const formData = new FormData();
      formData.append("callImage", uploadFile);
      formData.append("jsonData", JSON.stringify(payload));
      // console.log("2");
      navigate("/livecall")
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        credentials: "include",
      });
      // console.log("3");
      // console.log('✌️response --->', response.status);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      // console.log('✌️responseData --->', responseData);
      // console.log("4")
      // if (responseData) {
        // navigate("/livecall");
        clearformdata();
      // }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFileUpload(selectedFile);
    }
  };

  const productOption = actualProductList.map((item) => 
 {  return {
    value:item.value,
    label:item.label
   }}
  )

  // alldimensionField.map((item) => {
  //   return {
  //     value: item.internalName,
  //     label: item.label,
  //   };
  // });
  // ___________________________________________________user Authentication____________________
  useEffect(() => {
    fetch(`${api_url}/getuser`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userObject = data.find(
          (user) => user._id === sessionStorage.userId
        );
        // setIsValidUser(userObject.active);
        if (!userObject.active) {
          navigate("/");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  // ____________________________________________________________________________________________

  //fetching data from database for selectbox

  useEffect(() => {
    const uniqueProductKeys = new Set();
    const fetchData = async () => {
      try {
        const response = await fetch(`${api_url}/newProductList`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const result2 = await result.flatMap((item) =>
          item.caps.map((cap) => {
            const key = `${item.productName}-${cap.capCategory}`;
            if (!uniqueProductKeys.has(key)) {
              uniqueProductKeys.add(key);
              return {
                value: key,
                label: `${item.productName} - ${cap.capCategory}`,
              };
            }
            return null;
          })
        );
        const finallist = await result2.filter((item) => item !== null);
        setActualProductList(finallist);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  
  return (
    <div>
      <Home />
      <div className="form-container">
        {view && (
          <div className="form-align">
            <Form id="productForm" onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Product</Label>
                    {/* <Input
                      id="productName"
                      name="productName"
                      type="select"
                      multiple
                      required="true"
                      value={products}
                      onChange={(e) => {
                        const selectedOption = e.target.value;
                        if (products.includes(selectedOption)) {
          
                          setProducts(
                            products.filter(
                              (product) => product !== selectedOption
                            )
                          );
                        } else {
            
                          setProducts([...products, selectedOption]);
                        }
                      }}
                    >
                      <option selected disabled value="">
                        --Select--
                      </option>
                      {actualProductList.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Input> */}
                    <Select id="productName" name="productName" maxMenuHeight={150} isMulti options={productOption} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="examplePassword">Quantity</Label>
                    <Input
                      placeholder=" Enter Quantity"
                      type="text "
                      name="quantity"
                      value={quantity}
                      onChange={handleChangequatity}
                      // step="any"                             
                      id="quantity"
                      required="true"
                      // min={0}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleCity">Buy/Sell</Label>
                    <Input
                      id="dealType"
                      name="dealType"
                      type="select"
                      placeholder="Select"
                      required="true"
                    >
                      <option selected disabled value="">
                        --Select--
                      </option>
                      <option>Buy</option>
                      <option>Sell</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleState">Script</Label>
                    <Input id="script" name="script" required="true" />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleZip">Above/Below/At</Label>
                    <Input
                      id="position"
                      name="position"
                      type="select"
                      required="true"
                    >
                      <option selected disabled value="">
                        --Select--
                      </option>
                      <option>Above</option>
                      <option>Below</option>
                      <option>At</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleCity">Entry Price</Label>
                    <Input
                      id="price1"
                      name="price1"
                      type="text"
                      // step="any"
                      // min={0}
                      required="true"
                      value={price1}
                      onChange={handleChangePrice}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleCity">Price-2</Label>
                    <Input
                      disabled
                      id="price2"
                      name="price2"
                      type="number"
                      step="any"
                      min={0}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleCity">Price-3</Label>
                    <Input
                      id="price3"
                      name="price3"
                      disabled
                      type="number"
                      min={0}
                      step="any"
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleState">With Target</Label>
                    <Input
                      id="target"
                      name="target"
                      type="text"
                      // step="any"
                      value={target}
                      onChange={handleChangeTarget}
                      required="true"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleZip">With Stop Loss</Label>
                    <Input
                      id="stopLoss"
                      name="stopLoss"
                      type="text"
                      value={stopLoss}
                      onChange={handleChangeStopLoss}
                      // step="any"
                      required="true"
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row>
                  <Col md={3}>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="exampleSelect">
                        Sample
                      </Label>
                    </FormGroup>
                  </Col>
                </Row> */}
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="exampleCity">Rationale</Label>
                    <textarea
                      class="form-control"
                      placeholder="Leave a comment here"
                      id="description"
                      rows={4}
                      name="description"
                      required="true"
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleFile">Upload Image</Label>
                    <Input
                      id="uploadFile"
                      name="uploadFile"
                      type="file"
                      required="true"
                      onChange={handleFileChange}
                    />
                    {fileSizeError ? (
                      <div style={{ color: "red" }}>{fileSizeError}</div>
                    ) : (
                      <FormText
                        color="muted"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        Image size should be less than 20KB.
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <br />
              {create && (
                <Row>
                  <Col md={12} className="d-flex justify-content-center">
                    <FormGroup>
                      <Button type="submit" className="save_btn">
                        Send{" "}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </Form>

            {/* Confirmation Popup */}
            <ConfirmationPopup
              isOpen={isPopupOpen}
              toggle={togglePopup}
              onConfirm={handlePopupConfirm}
              dealTypeValue={dealType}
              scriptTypeValue={script}
              positionTypeValue={position}
              priceTypeValue={price1}
              targetTypeValue={target}
              stopLossValue={stopLoss}
            />
          </div>
        )}
        {!view && (
          <div className="form-align loading-gif">
            <img src="/loading.gif" alt="loading"></img>
          </div>
        )}
      </div>
    </div>
  );
};

export default SendCall;
